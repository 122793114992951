var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        "label-width": "120px",
                        inline: "",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { display: "block" },
                          attrs: { label: "审核状态：" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.reviewStatus,
                                callback: function ($$v) {
                                  _vm.reviewStatus = $$v
                                },
                                expression: "reviewStatus",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v("全部"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "0" } }, [
                                _vm._v("平台待审核"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v("平台审核失败"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "2" } }, [
                                _vm._v("微信审核失败"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio-button", { attrs: { label: "3" } }, [
                                _vm._v("微信审核成功"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["platform:seckill:product:list"],
                              expression: "['platform:seckill:product:list']",
                            },
                          ],
                          attrs: { label: "商户名称：" },
                        },
                        [
                          _c("merchant-name", {
                            attrs: { merIdChecked: _vm.merIds },
                            on: { getMerId: _vm.getMerId },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "inline",
                          attrs: { label: "商户分类", prop: "categoryId" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: { placeholder: "请选择商户分类" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableForm.merType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "merType", $$v)
                                },
                                expression: "tableForm.merType",
                              },
                            },
                            _vm._l(_vm.merchantClassify, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "直播状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableForm.liveStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "liveStatus", $$v)
                                },
                                expression: "tableForm.liveStatus",
                              },
                            },
                            _vm._l(_vm.studioStatusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商城显示状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableForm.storeShow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "storeShow", $$v)
                                },
                                expression: "tableForm.storeShow",
                              },
                            },
                            _vm._l(_vm.studioShowStatusList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "推荐级别：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth",
                              attrs: { placeholder: "请选择", clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableForm.star,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableForm, "star", $$v)
                                },
                                expression: "tableForm.star",
                              },
                            },
                            _vm._l(_vm.recommendedLevelStatus, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "width100",
                          attrs: { label: "关键字：" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder:
                                  "请输入直播间名称/ID/主播昵称/主播微信号/主播副号微信号",
                                size: "small",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.keywords,
                                callback: function ($$v) {
                                  _vm.keywords = $$v
                                },
                                expression: "keywords",
                              },
                            },
                            [
                              _c("el-button", {
                                staticClass: "el-button-solt",
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "40" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "roomName",
                  label: "直播间名称",
                  "min-width": "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "anchorName",
                  label: "主播昵称",
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "anchorWechat",
                  label: "主播微信号",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "14",
                attrs: { label: "显示在商城", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["platform:mp:live:room:showstore"])
                          ? _c("el-switch", {
                              attrs: {
                                "active-value": 1,
                                "inactive-value": 0,
                                "active-text": "显示",
                                "inactive-text": "隐藏",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.onchangeStoreShow(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.storeShow,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "storeShow", $$v)
                                },
                                expression: "scope.row.storeShow",
                              },
                            })
                          : _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.storeShow === 1 ? "显示" : "隐藏"
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "15",
                attrs: { label: "官方收录", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["platform:mp:live:room:isfeedspubic"])
                          ? _c("el-switch", {
                              attrs: {
                                disabled: scope.row.reviewStatus === 3,
                                "active-value": 1,
                                "inactive-value": 0,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.onchangeIsFeeds(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.isFeedsPublic,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isFeedsPublic", $$v)
                                },
                                expression: "scope.row.isFeedsPublic",
                              },
                            })
                          : _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.isFeedsPublic === 1
                                    ? "开启"
                                    : "关闭"
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "16",
                attrs: { label: "评论", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["platform:mp:live:room:updatecomment"])
                          ? _c("el-switch", {
                              attrs: {
                                disabled:
                                  scope.row.liveStatus == 101 &&
                                  scope.row.reviewStatus === 3,
                                "active-value": 1,
                                "inactive-value": 0,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.onchangeIsCommen(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.closeComment,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "closeComment", $$v)
                                },
                                expression: "scope.row.closeComment",
                              },
                            })
                          : _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.closeComment === 1 ? "开启" : "关闭"
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                key: "18",
                attrs: { label: "回放", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["platform:mp:live:room:updatereplay"])
                          ? _c("el-switch", {
                              attrs: {
                                disabled: scope.row.liveStatus !== 101,
                                "active-value": 1,
                                "inactive-value": 0,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.onchangeCloseReplay(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.closeReplay,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "closeReplay", $$v)
                                },
                                expression: "scope.row.closeReplay",
                              },
                            })
                          : _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.closeReplay === 1 ? "开启" : "关闭"
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "直播状态", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("broadcastStatusFilter")(
                                scope.row.liveStatus
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "审核状态", "min-width": "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.reviewStatus === 1 ||
                        scope.row.reviewStatus === 2
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: scope.row.reviewReason,
                                  placement: "top",
                                },
                              },
                              [
                                scope.row.reviewStatus === 1
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v("平台审核失败")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.reviewStatus === 2
                                  ? _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v("微信审核失败")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                scope.row.reviewStatus === 0
                                  ? _c("el-tag", { attrs: { type: "info" } }, [
                                      _vm._v("待审核"),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.reviewStatus === 3
                                  ? _c("el-tag", [_vm._v("微信审核成功")])
                                  : _vm._e(),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "startTime",
                  "min-width": "150",
                  label: "直播开始时间",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "endTime",
                  "min-width": "150",
                  label: "直播计划结束时间",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", "min-width": "150", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:mp:live:room:list"],
                                expression: "['platform:mp:live:room:list']",
                              },
                            ],
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onStudioDetails(scope.row, false)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        scope.row.reviewStatus === 0
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["platform:mp:live:room:review"],
                                    expression:
                                      "['platform:mp:live:room:review']",
                                  },
                                ],
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onStudioDetails(scope.row, true)
                                  },
                                },
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:mp:live:room:delete"],
                                expression: "['platform:mp:live:room:delete']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row, scope.$index)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableForm.limit,
                  "current-page": _vm.tableForm.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            "custom-class": "demo-drawer",
            direction: "rtl",
            visible: _vm.dialogVisible,
            size: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: function ($event) {
              return _vm.close("ruleForm")
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "demo-drawer_title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("直播详情")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "demo-drawer__content",
            },
            [
              _c("div", { staticClass: "description" }, [
                _c("div", { staticClass: "title" }, [_vm._v("直播间信息")]),
                _vm._v(" "),
                _c("div", { staticClass: "acea-row" }, [
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("直播间状态："),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm._f("broadcastStatusFilter")(_vm.roomInfo.liveStatus)
                      ) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("直播间名称："),
                    ]),
                    _vm._v(_vm._s(_vm.roomInfo.roomName)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("直播间ID："),
                    ]),
                    _vm._v(_vm._s(_vm.roomInfo.id)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("主播昵称："),
                    ]),
                    _vm._v(_vm._s(_vm.roomInfo.anchorName)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("主播微信号："),
                    ]),
                    _vm._v(_vm._s(_vm.roomInfo.anchorWechat) + "\n          "),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("主播副号微信号："),
                    ]),
                    _vm._v(
                      _vm._s(_vm.roomInfo.subAnchorWechat) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("创建者微信号："),
                    ]),
                    _vm._v(_vm._s(_vm.roomInfo.activityName) + "\n          "),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("审核结果："),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm._f("roomReviewStatusFilter")(
                          _vm.roomInfo.reviewStatus
                        )
                      ) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.roomInfo.reviewStatus === 1 ||
                            _vm.roomInfo.reviewStatus === 2,
                          expression:
                            "roomInfo.reviewStatus === 1 || roomInfo.reviewStatus === 2",
                        },
                      ],
                      staticClass: "description-term sp100",
                    },
                    [
                      _c("label", { staticClass: "name" }, [
                        _vm._v("审核失败原因："),
                      ]),
                      _vm._v(
                        _vm._s(_vm.roomInfo.reviewReason) + "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("直播开始时间："),
                    ]),
                    _vm._v(_vm._s(_vm.roomInfo.startTime)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("直播预计结束时间："),
                    ]),
                    _vm._v(_vm._s(_vm.roomInfo.endTime) + "\n          "),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("直播间类型："),
                    ]),
                    _vm._v(
                      _vm._s(_vm.roomInfo.type === 1 ? "推流" : "手机直播") +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("直播间点赞："),
                    ]),
                    _vm._v(
                      _vm._s(_vm._f("roomShowFilter")(_vm.roomInfo.closeLike)) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("直播间评论："),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm._f("roomShowFilter")(_vm.roomInfo.closeComment)
                      ) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("直播间货架："),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm._f("roomShowFilter")(_vm.roomInfo.closeGoods)
                      ) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("直播间客服："),
                    ]),
                    _vm._v(
                      _vm._s(_vm._f("roomShowFilter")(_vm.roomInfo.closeKf)) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("直播间回放："),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm._f("roomShowFilter")(_vm.roomInfo.closeReplay)
                      ) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("直播间分享："),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm._f("roomShowFilter")(_vm.roomInfo.closeShare)
                      ) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v("直播间官方收录："),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.roomInfo.isFeedsPublic === 1 ? "开启" : "关闭"
                      ) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [_vm._v("背景图：")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "demo-image__preview mr10" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: _vm.roomInfo.coverImgLocal,
                            "preview-src-list": [_vm.roomInfo.coverImgLocal],
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [_vm._v("封面图：")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "demo-image__preview mr10" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: _vm.roomInfo.feedsImgLocal,
                            "preview-src-list": [_vm.roomInfo.feedsImgLocal],
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [_vm._v("分享图：")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "demo-image__preview mr10" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: _vm.roomInfo.shareImgLocal,
                            "preview-src-list": [_vm.roomInfo.shareImgLocal],
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.roomInfo.reviewStatus === 3
                    ? _c("div", { staticClass: "description-term sp100" }, [
                        _c("label", { staticClass: "name" }, [
                          _vm._v("直播小程序码："),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "demo-image__preview mr10" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: _vm.roomInfo.qrcodeUrl,
                                "preview-src-list": [_vm.roomInfo.qrcodeUrl],
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _vm.goodsList.length > 0 ? _c("el-divider") : _vm._e(),
              _vm._v(" "),
              _vm.goodsList.length > 0
                ? _c("div", { staticClass: "description" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("产品信息")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "acea-row mb20" }, [
                      _c(
                        "div",
                        { staticStyle: { width: "100%" } },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.listLoading,
                                  expression: "listLoading",
                                },
                              ],
                              ref: "tableList",
                              staticStyle: { width: "100%" },
                              attrs: {
                                "row-key": "id",
                                data: _vm.goodsList,
                                size: "mini",
                                border: "",
                                "default-expand-all": "",
                                "tree-props": { children: "children" },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "id",
                                  label: "ID",
                                  "min-width": "50",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "产品图", "min-width": "100" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "demo-image__preview",
                                            },
                                            [
                                              _c("el-image", {
                                                staticStyle: {
                                                  width: "36px",
                                                  height: "36px",
                                                },
                                                attrs: {
                                                  src: scope.row
                                                    .coverImgUrlLocal,
                                                  "preview-src-list": [
                                                    scope.row.coverImgUrlLocal,
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3821445548
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "name",
                                  label: "产品名称",
                                  "min-width": "120",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "价格类型", "min-width": "80" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("priceTypeFilter")(
                                                  scope.row.priceType
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  411771551
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "价格", "min-width": "100" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.priceType === 1
                                            ? _c("span", [
                                                _vm._v(_vm._s(scope.row.price)),
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.price +
                                                      "~" +
                                                      scope.row.price2
                                                  )
                                                ),
                                              ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3287871236
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isReview,
                  expression: "isReview",
                },
              ],
              staticClass: "demo-drawer__footer",
            },
            [
              _c("div", { staticClass: "from-foot-btn drawer_fix" }, [
                _c(
                  "div",
                  { staticClass: "acea-row justify-content" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["platform:seckill:product:audit"],
                            expression: "['platform:seckill:product:audit']",
                          },
                          {
                            name: "debounceClick",
                            rawName: "v-debounceClick",
                            value: function () {
                              _vm.onSubmit(1)
                            },
                            expression:
                              "\n              () => {\n                onSubmit(1);\n              }\n            ",
                          },
                        ],
                        staticStyle: { "margin-left": "0" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.loadingBtn ? "提交中 ..." : "审核拒绝")
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["platform:seckill:product:audit"],
                            expression: "['platform:seckill:product:audit']",
                          },
                          {
                            name: "debounceClick",
                            rawName: "v-debounceClick",
                            value: function () {
                              _vm.onSubmit(3)
                            },
                            expression:
                              "\n              () => {\n                onSubmit(3);\n              }\n            ",
                          },
                        ],
                        attrs: { type: "primary" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.loadingBtnSuccess ? "提交中 ..." : "审核通过"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }