var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          ref: "userDetailFrom",
          attrs: { visible: _vm.dialogUserDetail, size: "1100px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogUserDetail = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("div", {
            staticClass: "demo-drawer_title",
            attrs: { slot: "title" },
            slot: "title",
          }),
          _vm._v(" "),
          _c("div", { staticClass: "demo-drawer__content" }, [
            _vm.userDetailData
              ? _c("div", { staticClass: "description" }, [
                  _c("div", { staticClass: "con-head" }, [
                    _c("img", {
                      attrs: { src: _vm.userDetailData.avatar, alt: "" },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "nickname" }, [
                      _vm._v(_vm._s(_vm.userDetailData.nickname)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "acea-row info-row" }, [
                    _c("div", { staticClass: "info-row-item" }, [
                      _c("div", { staticClass: "info-row-item-title" }, [
                        _vm._v("余额"),
                      ]),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(_vm.userDetailData.nowMoney))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-row-item" }, [
                      _c("div", { staticClass: "info-row-item-title" }, [
                        _vm._v("积分"),
                      ]),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(_vm.userDetailData.integral))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-row-item" }, [
                      _c("div", { staticClass: "info-row-item-title" }, [
                        _vm._v("经验"),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s(_vm.userDetailData.experience)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-row-item" }, [
                      _c("div", { staticClass: "info-row-item-title" }, [
                        _vm._v("等级"),
                      ]),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(_vm.userDetailData.level))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-row-item" }, [
                      _c("div", { staticClass: "info-row-item-title" }, [
                        _vm._v("佣金"),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s(_vm.userDetailData.brokeragePrice)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-row-item" }, [
                      _c("div", { staticClass: "info-row-item-title" }, [
                        _vm._v("消费次数"),
                      ]),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(_vm.userDetailData.payCount))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-row-item" }, [
                      _c("div", { staticClass: "info-row-item-title" }, [
                        _vm._v("连续签到"),
                      ]),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(_vm.userDetailData.signNum))]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "user-info" }, [
                    _c("div", { staticClass: "section" }, [
                      _c("div", { staticClass: "section-hd" }, [
                        _vm._v("基本信息"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "section-bd" }, [
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("用户ID：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.userDetailData.id)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("真实姓名：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.userDetailData.realName || "-")),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("用户账号：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.userDetailData.account || "-")),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("用户电话：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.userDetailData.phone)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("生日：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.userDetailData.birthday || "-")),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("性别：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.userDetailData.sex == 0
                                    ? "未知"
                                    : _vm.userDetailData.sex == 1
                                    ? "男"
                                    : _vm.userDetailData.sex == 2
                                    ? "女"
                                    : "保密"
                                ) +
                                "\n                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("国家：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(
                                _vm.userDetailData.country == "CN"
                                  ? "中国"
                                  : "其他" || "-"
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("用户地址：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.userDetailData.province +
                                    _vm.userDetailData.city +
                                    _vm.userDetailData.district +
                                    _vm.userDetailData.address || "-"
                                ) +
                                "\n                "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "user-info" }, [
                    _c("div", { staticClass: "section" }, [
                      _c("div", { staticClass: "section-hd-other" }, [
                        _vm._v("用户概况"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "section-bd" }, [
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("创建ip：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.userDetailData.addIp || "-")),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("注册类型：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.userDetailData.registerType == "wechat"
                                    ? "公众号"
                                    : _vm.userDetailData.registerType ==
                                      "routine"
                                    ? "小程序"
                                    : _vm.userDetailData.registerType == "h5"
                                    ? "H5"
                                    : _vm.userDetailData.registerType == "iosWx"
                                    ? "微信ios"
                                    : _vm.userDetailData.registerType ==
                                      "androidWx"
                                    ? "微信安卓"
                                    : "ios" || "-"
                                ) +
                                "\n                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("添加时间：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(_vm.userDetailData.createTime || "-")
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("状态：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(
                                _vm.userDetailData.status == true
                                  ? "正常"
                                  : "禁止" || "-"
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("标签：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("tagFilter")(
                                  _vm.userDetailData.tagId || "-"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("是否关联ios：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(
                                _vm.userDetailData.isBindingIos == true
                                  ? "是"
                                  : "否" || "-"
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("是否注销：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(
                                _vm.userDetailData.isLogoff == true
                                  ? "是"
                                  : "否" || "-"
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("是否关联微信android：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(
                                _vm.userDetailData.isWechatAndroid == true
                                  ? "是"
                                  : "否" || "-"
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("是否关联微信ios：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(
                                _vm.userDetailData.isWechatIos == true
                                  ? "是"
                                  : "否" || "-"
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("是否关联公众号：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(
                                _vm.userDetailData.isWechatPublic == true
                                  ? "是"
                                  : "否" || "-"
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("是否关联小程序：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(
                                _vm.userDetailData.isWechatRoutine == true
                                  ? "是"
                                  : "否" || "-"
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("最后一次登录ip：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.userDetailData.lastIp || "-")),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("最后一次登录时间：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(_vm.userDetailData.lastLoginTime || "-")
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("注销时间：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(_vm.userDetailData.logoffTime || "-")
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "user-info" }, [
                    _c("div", { staticClass: "section" }, [
                      _c("div", { staticClass: "section-hd" }, [
                        _vm._v("推广信息"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "section-bd" }, [
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("是否为推广员：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(
                                _vm.userDetailData.isPromoter == true
                                  ? "是"
                                  : "否" || "-"
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("下级人数：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(_vm.userDetailData.spreadCount || "-")
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("成为分销员时间：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(_vm.userDetailData.promoterTime || "-")
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("上级推广员昵称：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(_vm.userDetailData.spreadName || "-")
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("上级推广员ID：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.userDetailData.spreadUid || "-")),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("绑定上级推广员时间：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(_vm.userDetailData.spreadTime || "-")
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "user-info" }, [
                    _c("div", { staticClass: "section" }, [
                      _c("div", { staticClass: "section-hd" }, [
                        _vm._v("用户备注"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "section-bd" }, [
                        _c("div", { staticClass: "item" }, [
                          _c("div", [_vm._v("备注：")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.userDetailData.mark || "-")),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }