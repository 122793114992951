var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "hasPermi",
              rawName: "v-hasPermi",
              value: ["platform:couponsOrder:page:list"],
              expression: "['platform:couponsOrder:page:list']",
            },
          ],
          staticClass: "box-card",
        },
        [
          _c("div", { staticClass: "clearfix" }, [
            _c(
              "div",
              { staticClass: "container" },
              [
                _c(
                  "el-form",
                  { attrs: { size: "small", "label-width": "100px" } },
                  [
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c(
                            "div",
                            { staticClass: "grid-content bg-purple" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "width100",
                                  attrs: { label: "订单号：" },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: "请输入订单号",
                                        size: "small",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.tableFrom.orderId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tableFrom,
                                            "orderId",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "tableFrom.orderId",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                          size: "small",
                                        },
                                        on: { click: _vm.seachList },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c(
                            "div",
                            { staticClass: "grid-content bg-purple" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "width100",
                                  attrs: { label: "云文旅名称：" },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: "请输入云文旅名称",
                                        size: "small",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.tableFrom.cinemaName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tableFrom,
                                            "cinemaName",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "tableFrom.cinemaName",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                          size: "small",
                                        },
                                        on: { click: _vm.seachList },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c(
                            "div",
                            { staticClass: "grid-content bg-purple" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "width100",
                                  attrs: { label: "用户昵称：" },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: "请输入用户昵称",
                                        size: "small",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.tableFrom.nickname,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tableFrom,
                                            "nickname",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "tableFrom.nickname",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                          size: "small",
                                        },
                                        on: { click: _vm.seachList },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c(
                            "div",
                            { staticClass: "grid-content bg-purple" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "width100",
                                  attrs: { label: "订单状态：" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        clearable: "",
                                        filterable: "",
                                        remote: "",
                                        placeholder: "请选择订单状态",
                                      },
                                      on: { change: _vm.seachList },
                                      model: {
                                        value: _vm.tableFrom.orderState,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tableFrom,
                                            "orderState",
                                            $$v
                                          )
                                        },
                                        expression: "tableFrom.orderState",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "待支付", value: "0" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { label: "已支付", value: "1" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "正在充值",
                                          value: "3",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "订单成功",
                                          value: "4",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "订单失败",
                                          value: "5",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: "超时未付",
                                          value: "8",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _c(
                            "div",
                            { staticClass: "grid-content bg-purple" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "width100",
                                  attrs: { label: "下单时间：" },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "300px" },
                                    attrs: {
                                      "value-format": "yyyy-MM-dd",
                                      format: "yyyy-MM-dd",
                                      size: "small",
                                      type: "daterange",
                                      placement: "bottom-end",
                                      placeholder: "自定义时间",
                                    },
                                    on: { change: _vm.onchangeTime },
                                    model: {
                                      value: _vm.timeVal,
                                      callback: function ($$v) {
                                        _vm.timeVal = $$v
                                      },
                                      expression: "timeVal",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-form-item", { staticClass: "width100" }, [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _vm.isNotImport
                            ? _c(
                                "el-upload",
                                {
                                  staticClass: "upload",
                                  staticStyle: { "margin-right": "20px" },
                                  attrs: {
                                    data: _vm.dataUpload,
                                    "before-upload": _vm.beforeAvatarUpload,
                                    "on-success": _vm.uploadSuccess,
                                    "on-progress": _vm.onProgress,
                                    "on-error": _vm.onError,
                                    action:
                                      "/api/admin/platform/order/film/importorder",
                                    "show-file-list": false,
                                    headers: _vm.uploadHeader,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermi",
                                          rawName: "v-hasPermi",
                                          value: ["admin:export:excel:order"],
                                          expression:
                                            "['admin:export:excel:order']",
                                        },
                                      ],
                                      attrs: { type: "primary", size: "small" },
                                      on: { click: _vm.orderImports },
                                    },
                                    [_vm._v("导入订单")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.checkPermi(["platform:couponsOrder:export"])
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.orderOutImports },
                                },
                                [_vm._v("导出订单")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "mr15",
                              attrs: {
                                type: "primary",
                                icon: "ios-search",
                                label: "default",
                                size: "small",
                              },
                              on: { click: _vm.seachList },
                            },
                            [_vm._v("查询")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "ResetSearch mr10",
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.reset("userFrom")
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                    _vm._l(_vm.orderInfoList, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.name,
                          staticStyle: { "margin-right": "10px" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.name) +
                              _vm._s(item.value) +
                              "\n            "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table",
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
                "header-cell-style": { fontWeight: "bold" },
                "row-key": function (row) {
                  return row.orderNo
                },
              },
            },
            [
              _vm.checkedCities.includes("订单号")
                ? _c("el-table-column", {
                    attrs: {
                      label: "订单号",
                      prop: "orderId",
                      "min-width": "220",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图片", prop: "orderId", "min-width": "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "demo-image__preview",
                            staticStyle: {
                              width: "35px",
                              height: "auto",
                              display: "inline-block",
                            },
                          },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.postImageUrl,
                                "preview-src-list": [scope.row.postImageUrl],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "cinemaName",
                  label: "云文旅名称",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _vm.checkedCities.includes("收货人")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "nickname",
                      label: "用户昵称",
                      "min-width": "150",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("实际支付")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "totalPrice",
                      label: "总价",
                      "min-width": "80",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("实际支付")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "payPrice",
                      label: "实际支付",
                      "min-width": "80",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("支付方式")
                ? _c("el-table-column", {
                    attrs: { label: "支付方式", "min-width": "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.payType != "mixture"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("payTypeFilter")(
                                          scope.row.payType
                                        )
                                      )
                                    ),
                                  ])
                                : _c("span", [
                                    _c("div", [
                                      _vm._v(
                                        "微信支付：" +
                                          _vm._s(scope.row.weixinMoney || 0)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        "余额支付：" +
                                          _vm._s(scope.row.balanceMoney || 0)
                                      ),
                                    ]),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1930468551
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("订单状态")
                ? _c("el-table-column", {
                    attrs: { label: "订单状态", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("orderStateFilter")(
                                      scope.row.orderState
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1754388319
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("下单时间")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "下单时间",
                      "min-width": "140",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "orderRemark",
                  label: "备注",
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "150",
                  fixed: "right",
                  align: "center",
                  "render-header": _vm.renderHeader,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["platform:couponsOrder:info"])
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onOrderDetails(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("订单详情")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.checkPermi(["platform:couponsOrder:info"])
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onOrderMark(scope.row)
                                  },
                                },
                              },
                              [_vm._v("订单备注")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.pageSize,
                  "current-page": _vm.tableFrom.pageIndex,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.card_select_show,
              expression: "card_select_show",
            },
          ],
          staticClass: "card_abs",
        },
        [
          [
            _c(
              "div",
              { staticClass: "cell_ht" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function ($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll",
                    },
                  },
                  [_vm._v("全选")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.checkSave()
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-checkbox-group",
              {
                on: { change: _vm.handleCheckedCitiesChange },
                model: {
                  value: _vm.checkedCities,
                  callback: function ($$v) {
                    _vm.checkedCities = $$v
                  },
                  expression: "checkedCities",
                },
              },
              _vm._l(_vm.columnData, function (item) {
                return _c(
                  "el-checkbox",
                  {
                    key: item,
                    staticClass: "check_cell",
                    attrs: { label: item },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c("OrderCouponsDetail", {
        ref: "orderDetail",
        attrs: { orderNo: _vm.orderNo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }