"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tools = require("@/utils/tools");
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Tinymce from '@/components/Tinymce/index';
var _default2 = exports.default = {
  name: '',
  props: {
    roomFormDataVisible: {
      type: Boolean,
      default: false
    },
    roomFormDataed: {
      type: [Object, Array],
      default: function _default() {
        return [];
      }
    },
    disabled: {
      type: [Boolean],
      default: true
    }
  },
  components: {},
  data: function data() {
    return {
      formLabelWidth: '120px',
      roomFormData: {},
      //   roomFormData: {
      //     bed: '',
      //     area: '',
      //     floor: '',
      //     people: '',
      //     wifi: 1,
      //     smoke: 1,
      //     window: 1,
      //     morning: 1,
      //     delivery: false,
      //     type: [
      //       {
      //         name: '',
      //         content: '',
      //       },
      //     ],
      //     sliderImages: [],
      //     content: `<p class="text-panel-line"><span style="font-size: 12pt;"><strong>确认较快</strong></span></p>
      //               <p class="text-panel-line">近期大多数订单可在5分钟内确认是否预订成功</p>
      //               <p class="text-panel-line"><span style="font-size: 12pt;"><strong>预订说明</strong></span></p>
      //               <p class="text-panel-line">最多预订60天最早入佳前180天0点前预订,最晚入住前7天23:59前预订,可享受当前优惠价格如需发票，请向酒店前台索取</p>
      //               <p class="text-panel-line"><span style="font-size: 12pt;"><strong>商家取消政策</strong></span></p>
      //               <p class="text-panel-line">订单确认后不可取消/ 变更，如未入住，酒店将扣除全额房费</p>
      //               <p class="text-panel-line"><span style="font-size: 12pt;"><strong>儿童及加床</strong></span></p>
      //               <p class="text-panel-line">该房型允许携带儿童入住。</p>
      //               <p class="text-panel-line"><span style="font-size: 12pt;"><strong>床铺</strong></span></p>
      //               <p class="text-panel-line">该房型不提供加床，不提供加婴儿床不接受18岁以下客人在无监护人陪同的情況下入住</p>
      //               <p class="text-panel-line"><span style="font-size: 12pt;"><strong>使用规则</strong></span></p>
      //               <p class="text-panel-line">直接消费，无需美团券，携带所有入住人的有效身份证办理入住入住必须按照一人一证请在12:00之后入住井于次日12:00之前退房;如需提前入住或延时退房，请咨询商家。入住需要押金，金额以前台为准</p>
      //               <p class="text-panel-line"><span style="font-size: 12pt;"><strong>房型说明</strong></span></p>
      //               <p class="text-panel-line">房型图片仅可代表其中部分房间以作为预订参考，实际提供房型信息请以文字信息为准，如文字信息不全或包含多种情况，请提前与商家沟通确认后再下单预订</p>
      //               `,
      //   },
      ruleRoom: {
        bed: [{
          required: true,
          message: '请输入房间床型',
          trigger: 'blur'
        }],
        area: [{
          required: true,
          message: '请输入房间面积',
          trigger: 'blur'
        }],
        floor: [{
          required: true,
          message: '请输入所在楼层',
          trigger: 'blur'
        }],
        people: [{
          required: true,
          message: '请输入可入住人数',
          trigger: 'blur'
        }],
        wifi: [{
          required: true,
          message: '请选择是否有免费Wi-Fi',
          trigger: 'change'
        }],
        smoke: [{
          required: true,
          message: '请选择是否禁止吸烟',
          trigger: 'change'
        }],
        window: [{
          required: true,
          message: '请选择是否有含窗',
          trigger: 'change'
        }],
        morning: [{
          required: true,
          message: '请选择是否含早',
          trigger: 'change'
        }],
        sliderImages: [{
          required: true,
          message: '请上传房间轮播图',
          type: 'array',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {},
  created: function created() {
    this.roomFormData = (0, _tools.deepClone)(this.roomFormDataed);
  },
  watch: {
    roomFormDataed: function roomFormDataed(newValue) {
      if (newValue) {
        this.roomFormData = (0, _tools.deepClone)(newValue);
      }
    }
  },
  methods: {
    // 点击产品图
    modalPicRoomTap: function modalPicRoomTap(tit, num, i, status) {
      var _this = this;
      if (_this.disabled) return;
      this.$modalUpload(function (img) {
        if (tit === '1' && !num) {
          _this.roomFormData.sliderImages = img[0].sattDir;
        }
        if (tit === '2' && !num) {
          if (img.length > 10) return this.$message.warning('最多选择10张图片！');
          if (img.length + _this.roomFormData.sliderImages.length > 10) return this.$message.warning('最多选择10张图片！');
          img.map(function (item) {
            _this.roomFormData.sliderImages.push(item.sattDir);
          });
        }
      }, tit, 'content');
    },
    // 移动
    handleRoomDragStart: function handleRoomDragStart(e, item) {
      if (!this.disabled) this.dragging = item;
    },
    handleRoomDragEnd: function handleRoomDragEnd(e, item) {
      if (!this.disabled) this.dragging = null;
    },
    handleRoomDragOver: function handleRoomDragOver(e) {
      if (!this.disabled) e.dataTransfer.dropEffect = 'move';
    },
    handleRoomDragEnter: function handleRoomDragEnter(e, item) {
      if (!this.disabled) {
        e.dataTransfer.effectAllowed = 'move';
        if (item === this.dragging) {
          return;
        }
        var newItems = _toConsumableArray(this.roomFormData.sliderImages);
        var src = newItems.indexOf(this.dragging);
        var dst = newItems.indexOf(item);
        newItems.splice.apply(newItems, [dst, 0].concat(_toConsumableArray(newItems.splice(src, 1))));
        this.roomFormData.sliderImages = newItems;
      }
    },
    handleRoomRemove: function handleRoomRemove(i) {
      this.roomFormData.sliderImages.splice(i, 1);
    },
    roomHandleClose: function roomHandleClose() {
      this.$emit('close');
    },
    //房间设置添加
    addType: function addType() {
      this.roomFormData.type.push({
        name: '',
        content: ''
      });
    },
    //房间设置删除
    delType: function delType(index) {
      this.roomFormData.type.splice(index, 1);
    },
    handleSubmitRoomNext: function handleSubmitRoomNext() {
      var _this2 = this;
      //   this.roomFormDataVisible = false;
      console.log(this.roomFormData, 'this.roomFormData');
      this.$refs['formRoom'].validate(function (valid) {
        if (valid) {
          //   if (this.currentTab++ > 2) this.currentTab = 0;
          _this2.$emit('succee', _this2.roomFormData);
        } else {
          _this2.$message.warning('请填写完整房间信息！');
        }
      });
    } // roomFormDataVisible
  }
};