var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            "destroy-on-close": "",
            visible: _vm.dialogVisible,
            direction: _vm.direction,
            size: "1200px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "demo-drawer_title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("订单详情")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "demo-drawer__content",
            },
            [
              _vm.orderDatalist
                ? _c(
                    "div",
                    { staticClass: "description" },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("订单信息")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "acea-row" }, [
                        _c(
                          "div",
                          { staticClass: "description-term" },
                          [
                            _c(
                              "font",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.orderDatalist.type === 1,
                                    expression: "orderDatalist.type === 1",
                                  },
                                ],
                                attrs: { color: "#f6ae02" },
                              },
                              [_vm._v("[视频号]")]
                            ),
                            _vm._v(" "),
                            _c(
                              "font",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.orderDatalist.type === 2,
                                    expression: "orderDatalist.type === 2",
                                  },
                                ],
                                attrs: { color: "#1890ff" },
                              },
                              [_vm._v("[秒杀]")]
                            ),
                            _vm._v(
                              "\n            订单号：" +
                                _vm._s(_vm.orderDatalist.orderId) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "description-term",
                            staticStyle: { color: "red" },
                          },
                          [
                            _vm._v("\n            订单状态：\n            "),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("orderStateFilter")(
                                    _vm.orderDatalist.orderState
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v("城市：" + _vm._s(_vm.orderDatalist.cityName)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "门店：" + _vm._s(_vm.orderDatalist.cinemaName)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "门店地址：" +
                              _vm._s(_vm.orderDatalist.cinemaAddress)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "联系方式：" +
                              _vm._s(_vm.orderDatalist.contactsPhone)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v("用户ID：" + _vm._s(_vm.orderDatalist.uid)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "总价：" + _vm._s(_vm.orderDatalist.totalPrice)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "创建时间：" + _vm._s(_vm.orderDatalist.createTime)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "更新时间：" + _vm._s(_vm.orderDatalist.updateTime)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v(
                            "实际支付：" + _vm._s(_vm.orderDatalist.payPrice)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description-term" }, [
                          _vm._v("\n            支付方式：\n            "),
                          _vm.orderDatalist.payType != "mixture"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("payTypeFilter")(
                                      _vm.orderDatalist.payType
                                    )
                                  )
                                ),
                              ])
                            : _c("span", [
                                _c("span", [
                                  _vm._v(
                                    " 微信支付：" +
                                      _vm._s(
                                        _vm.orderDatalist.weixinMoney || 0
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", [_vm._v("、")]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "余额支付：" +
                                      _vm._s(
                                        _vm.orderDatalist.balanceMoney || 0
                                      )
                                  ),
                                ]),
                              ]),
                        ]),
                        _vm._v(" "),
                        _vm.orderDatalist.annualBalance &&
                        _vm.orderDatalist.annualBalance.length
                          ? _c(
                              "div",
                              {
                                staticClass: "description-term",
                                staticStyle: { display: "flex" },
                              },
                              [
                                _c("div", [_vm._v("余额使用年度：")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  _vm._l(
                                    _vm.orderDatalist.annualBalance,
                                    function (item, index) {
                                      return _c("div", { key: index }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.annual) +
                                            "年度 -" +
                                            _vm._s(item.money) +
                                            "\n              "
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _vm.orderDatalist.seatNames &&
                      _vm.orderDatalist.seatNames.length
                        ? _c("div", { staticClass: "qrContent" }, [
                            _c(
                              "div",
                              { staticClass: "qrList" },
                              _vm._l(
                                _vm.orderDatalist.seatNames,
                                function (code, codeI) {
                                  return _c(
                                    "div",
                                    { key: codeI, staticClass: "qrCode" },
                                    [
                                      _c("div", { staticClass: "number" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.orderDatalist.ticketCodelist[
                                              codeI
                                            ]
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "qrCodeBox",
                                        attrs: { id: "qrCode" + code },
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "tips" }, [
                                        _vm._v("取餐码"),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.orderDatalist.omType != 8 &&
                      _vm.orderDatalist.omType != 16 &&
                      _vm.orderDatalist.ticketCodelist &&
                      _vm.orderDatalist.ticketCodelist.length
                        ? _c("div", { staticClass: "qrContent" }, [
                            _c(
                              "div",
                              { staticClass: "qrList" },
                              _vm._l(
                                _vm.orderDatalist.ticketCodelist,
                                function (code, codeI) {
                                  return _c(
                                    "div",
                                    { key: codeI, staticClass: "qrCodeOn" },
                                    [
                                      _c("div", { staticClass: "code" }, [
                                        _vm._v(_vm._s(code)),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "tips" }, [
                                        _vm._v("取餐码"),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      [
                        _c("el-divider"),
                        _vm._v(" "),
                        _c("div", { staticClass: "title" }, [
                          _vm._v("产品信息"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              data: _vm.orderDatalist.products,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "产品图", width: "80" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                          },
                                          [
                                            _c("el-image", {
                                              attrs: {
                                                src: scope.row.imageUrl,
                                                "preview-src-list": [
                                                  scope.row.imageUrl,
                                                ],
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2116023174
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "产品名称",
                                prop: "nameCn",
                                "min-width": "200",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                prop: "config",
                                "min-width": "200",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "",
                                prop: "config",
                                width: "100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.refundStatus
                                          ? _c(
                                              "el-tag",
                                              {
                                                attrs: {
                                                  effect: "dark",
                                                  size: "mini",
                                                  type: "danger",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "已退款 x" +
                                                    _vm._s(scope.row.refundNum)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3552149884
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                prop: "quantity",
                                "min-width": "90",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "bshPrice",
                                label: "产品原价售价",
                                "min-width": "90",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "bshPriceHead",
                                label: "售价",
                                "min-width": "90",
                                align: "center",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      _vm._v(" "),
                      [
                        _c("el-divider"),
                        _vm._v(" "),
                        _c("div", { staticClass: "title" }, [
                          _vm._v("优惠信息"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "acea-row" }, [
                          _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "\n              共优惠金额：" +
                                _vm._s(
                                  (
                                    _vm.orderDatalist.totalPrice -
                                    _vm.orderDatalist.payPrice
                                  ).toFixed(2)
                                ) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "优惠券名称：" +
                                _vm._s(_vm.orderDatalist.coupnName)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "优惠券金额：" +
                                _vm._s(_vm.orderDatalist.couponPrice)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "description-term" }, [
                            _vm._v(
                              "分佣抵扣金：" +
                                _vm._s(_vm.orderDatalist.brokeragePrice)
                            ),
                          ]),
                        ]),
                      ],
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }