var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "100px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "用户编号：" } },
        [
          _c("el-input", {
            staticClass: "selWidth",
            attrs: { disabled: "" },
            model: {
              value: _vm.ruleForm.id,
              callback: function ($$v) {
                _vm.$set(
                  _vm.ruleForm,
                  "id",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "ruleForm.id",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "用户地址：" } },
        [
          _c("el-input", {
            staticClass: "selWidth",
            attrs: { disabled: "" },
            model: {
              value: _vm.ruleForm.province + _vm.ruleForm.city,
              callback: function ($$v) {
                _vm.$set(
                  _vm.ruleForm.province + _vm.ruleForm,
                  "city",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "ruleForm.province + ruleForm.city",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "用户标签：" } },
        [
          _c(
            "el-select",
            {
              staticClass: "selWidth",
              attrs: {
                placeholder: "请选择",
                clearable: "",
                filterable: "",
                multiple: "",
              },
              model: {
                value: _vm.labelData,
                callback: function ($$v) {
                  _vm.labelData = $$v
                },
                expression: "labelData",
              },
            },
            _vm._l(_vm.labelLists, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { value: item.id, label: item.name },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "用户姓名：" } },
        [
          _c("el-input", {
            staticClass: "selWidth",
            model: {
              value: _vm.ruleForm.systemRemark,
              callback: function ($$v) {
                _vm.$set(
                  _vm.ruleForm,
                  "systemRemark",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "ruleForm.systemRemark",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "生日：" } },
        [
          _c("el-date-picker", {
            attrs: {
              "value-format": "yyyy-MM-dd",
              format: "yyyy-MM-dd",
              type: "date",
              placeholder: "选择日期",
            },
            model: {
              value: _vm.ruleForm.birthday,
              callback: function ($$v) {
                _vm.$set(_vm.ruleForm, "birthday", $$v)
              },
              expression: "ruleForm.birthday",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "用户备注：" } },
        [
          _c("el-input", {
            staticClass: "selWidth",
            attrs: { type: "textarea" },
            model: {
              value: _vm.ruleForm.mark,
              callback: function ($$v) {
                _vm.$set(
                  _vm.ruleForm,
                  "mark",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "ruleForm.mark",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "推广员" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleForm.isPromoter,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "isPromoter", $$v)
                },
                expression: "ruleForm.isPromoter",
              },
            },
            [
              _c("el-radio", { attrs: { label: true } }, [_vm._v("开启")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: false } }, [_vm._v("关闭")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "状态" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleForm.status,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "status", $$v)
                },
                expression: "ruleForm.status",
              },
            },
            [
              _c("el-radio", { attrs: { label: true } }, [_vm._v("开启")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: false } }, [_vm._v("关闭")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["platform:user:update"],
                  expression: "['platform:user:update']",
                },
              ],
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("提交")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resetForm("ruleForm")
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }