"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cdkeyDeleteApi = cdkeyDeleteApi;
exports.cdkeyExport = void 0;
exports.cdkeyInfoApi = cdkeyInfoApi;
exports.cdkeyListApi = cdkeyListApi;
exports.cdkeySaveApi = cdkeySaveApi;
exports.cdkeyUpdateApi = cdkeyUpdateApi;
exports.handleExport = exports.getStatistic = exports.getOrderList = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 卡密管理 列表
 * @param pram
 */
function cdkeyListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/cdkey/list2",
    method: 'get',
    params: params
  });
}
/**
* 卡密 新增
* @param pram
*/
function cdkeySaveApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/cdkey/add",
    method: 'post',
    data: data
  });
}

/**
 * 卡密 编辑
 *  @param pram
 */
function cdkeyUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/cdkey/edit",
    method: 'post',
    data: data
  });
}
/**
 * 卡密 详情
 * @param pram
 */
function cdkeyInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/cdkey/detail",
    method: 'get',
    params: params
  });
}

/**
 * 卡密 删除
 * @param pram
 */
function cdkeyDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/cdkey/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 卡密 删除
 * @param pram
 */
var cdkeyExport = exports.cdkeyExport = function cdkeyExport(url, data) {
  return (0, _request.default)({
    url: url,
    method: 'post',
    data: data
    // responseType: 'blob'
  });
};

/**
 * 年度统计用户所用余额
 */
var getStatistic = exports.getStatistic = function getStatistic() {
  return (0, _request.default)({
    url: '/admin/platform/balance/statistic',
    method: 'get'
  });
};

/**
 * 用户年度余额使用明细
 */
var getOrderList = exports.getOrderList = function getOrderList(params) {
  return (0, _request.default)({
    url: '/admin/platform/balance/orderList',
    method: 'get',
    params: params
  });
};

/**
 * 用户年度余额使用明细导出
 */
var handleExport = exports.handleExport = function handleExport(data) {
  return (0, _request.default)({
    url: '/admin/platform/balance/export',
    method: 'post',
    data: data
  });
};