var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card mt20" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["platform:coupon:user:page:list"],
                  expression: "['platform:coupon:user:page:list']",
                },
              ],
              ref: "headerBox",
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        inline: "",
                        size: "small",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠券名称：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入优惠券名称",
                                clearable: "",
                              },
                              model: {
                                value: _vm.name,
                                callback: function ($$v) {
                                  _vm.name =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "name",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "领取人：" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请输入用户昵称",
                                clearable: "",
                              },
                              model: {
                                value: _vm.nickname,
                                callback: function ($$v) {
                                  _vm.nickname =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "nickname",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "使用状态：" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                placeholder: "请选择使用状态",
                                clearable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "status", $$v)
                                },
                                expression: "tableFrom.status",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "已使用", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "未使用", value: 0 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "已失效", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "优惠券ID", "min-width": "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "优惠券名称",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "user", label: "用户信息", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(scope.row.nickname) +
                              " / " +
                              _vm._s(scope.row.uid)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "money", label: "面值", "min-width": "90" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "startTime",
                  label: "使用有效期",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.startTime
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.startTime) +
                                  " -"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            " +
                                  _vm._s(scope.row.endTime) +
                                  "\n          "
                              ),
                            ])
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "category",
                  label: "使用范围",
                  "min-width": "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("categoryIdsType")(scope.row.category)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "receiveType",
                  label: "领取方式",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("receiveType")(scope.row.receiveType))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "领取时间", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.createTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "stock", label: "使用状态", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 1
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("已使用"),
                            ])
                          : scope.row.status == 0
                          ? _c("el-tag", [_vm._v("未使用")])
                          : _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("已失效"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "使用时间", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.useTime))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }