var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "div",
        { staticClass: "container_box" },
        [
          _c("el-card", { staticClass: "box-card mb20" }, [
            _c("div", { staticClass: "acea-row row-center row-middle" }, [
              _c(
                "div",
                {
                  staticClass: "pointer",
                  on: {
                    click: function ($event) {
                      return _vm.back()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-arrow-left" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "pl10" }, [_vm._v("返回")]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "f-s-18 f-w-500 ml32 add_title" }, [
                _vm._v(
                  _vm._s(_vm.pageType ? "添加产品" : _vm.title + "秒杀活动")
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-card",
            { staticClass: "box-card box-body" },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  !_vm.pageType
                    ? _c("el-tab-pane", {
                        attrs: { label: "基础设置", name: "first" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: {
                      label:
                        !_vm.isEdit || _vm.pageType ? "添加产品" : "产品列表",
                      name: "second",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                    size: "small",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                    },
                    [
                      _vm.activeName == "first" && !_vm.pageType
                        ? [
                            _c(
                              "el-form-item",
                              { attrs: { label: "活动名称：", prop: "name" } },
                              [
                                _c("el-input", {
                                  staticClass: "selWidth",
                                  attrs: {
                                    disabled: _vm.pageType,
                                    placeholder: "请输入活动名称",
                                  },
                                  model: {
                                    value: _vm.ruleForm.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "name", $$v)
                                    },
                                    expression: "ruleForm.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "活动日期：", prop: "timeVal" },
                              },
                              [
                                _c("el-date-picker", {
                                  staticClass: "selWidth",
                                  attrs: {
                                    disabled: _vm.pageType,
                                    "value-format": "yyyy-MM-dd",
                                    align: "right",
                                    "unlink-panels": "",
                                    format: "yyyy-MM-dd",
                                    size: "small",
                                    type: "daterange",
                                    placement: "bottom-end",
                                    placeholder: "自定义时间",
                                    "picker-options": _vm.pickerOptions,
                                  },
                                  on: { change: _vm.onchangeTime },
                                  model: {
                                    value: _vm.ruleForm.timeVal,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "timeVal", $$v)
                                    },
                                    expression: "ruleForm.timeVal",
                                  },
                                }),
                                _vm._v(" "),
                                _c("p", { staticClass: "desc mt10" }, [
                                  _vm._v(
                                    "设置活动开始日期与结束日期，用户可以在有效时间内参与秒杀"
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "秒杀场次:", prop: "timeVal2" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "selWidth",
                                    attrs: {
                                      disabled: _vm.pageType,
                                      placeholder: "请选择秒杀场次",
                                      multiple: "",
                                      cearable: "",
                                    },
                                    on: { change: _vm.onchangeTime2 },
                                    model: {
                                      value: _vm.ruleForm.timeVal2,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "timeVal2", $$v)
                                      },
                                      expression: "ruleForm.timeVal2",
                                    },
                                  },
                                  _vm._l(_vm.spikeTimeList, function (item) {
                                    return _c("el-option", {
                                      key: item.id + "onl",
                                      attrs: {
                                        label:
                                          item.name +
                                          " | " +
                                          item.startTime +
                                          "-" +
                                          item.endTime,
                                        value: item.id,
                                        disabled: item.status === 0,
                                      },
                                    })
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c("p", { staticClass: "desc mt10" }, [
                                  _vm._v(
                                    "\n                选择产品开始时间段，该时间段内用户可参与购买；其它时间段会显示活动未开始或已结束，可多选\n              "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "活动限购:" } },
                              [
                                _c("el-input-number", {
                                  staticClass: "selWidth",
                                  attrs: {
                                    disabled: _vm.pageType,
                                    "controls-position": "right",
                                    min: 0,
                                    max: 99999,
                                  },
                                  model: {
                                    value: _vm.ruleForm.allQuota,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "allQuota", $$v)
                                    },
                                    expression: "ruleForm.allQuota",
                                  },
                                }),
                                _vm._v(" "),
                                _c("p", { staticClass: "desc mt10" }, [
                                  _vm._v(
                                    "\n                活动有效期内每个用户可购买该产品总数限制。例如设置为4，表示本次活动有效期内，每个用户最多可购买总数4个，0为不限购\n              "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "单次限购:" } },
                              [
                                _c("el-input-number", {
                                  staticClass: "selWidth",
                                  attrs: {
                                    disabled: _vm.pageType,
                                    "controls-position": "right",
                                    min: 0,
                                    max: 99999,
                                  },
                                  model: {
                                    value: _vm.ruleForm.oneQuota,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "oneQuota", $$v)
                                    },
                                    expression: "ruleForm.oneQuota",
                                  },
                                }),
                                _vm._v(" "),
                                _c("p", { staticClass: "desc mt10" }, [
                                  _vm._v(
                                    "\n                用户参与秒杀时，一次购买最大数量限制。例如设置为2，表示参与秒杀时，用户一次购买数量最大可选择2个，0为不限购\n              "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "产品范围:" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "selWidth",
                                    attrs: {
                                      disabled: _vm.pageType,
                                      placeholder: "请选择产品分类",
                                      multiple: "",
                                    },
                                    model: {
                                      value: _vm.ruleForm.proCategorylist,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "proCategorylist",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.proCategorylist",
                                    },
                                  },
                                  _vm._l(
                                    _vm.adminProductClassify,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id + "onl",
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                          disabled: !item.isShow,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                                _vm._v(" "),
                                _c("p", { staticClass: "desc mt10" }, [
                                  _vm._v(
                                    "设置秒杀活动可以参与的产品分类，可多选，不选为全品类产品。"
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "参与门槛:", prop: "merStars" },
                              },
                              [
                                _c("el-rate", {
                                  staticStyle: { "margin-top": "8px" },
                                  attrs: { disabled: _vm.pageType },
                                  on: {
                                    change: function ($event) {
                                      return _vm.getList(1)
                                    },
                                  },
                                  model: {
                                    value: _vm.ruleForm.merStars,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "merStars", $$v)
                                    },
                                    expression: "ruleForm.merStars",
                                  },
                                }),
                                _vm._v(" "),
                                _c("p", { staticClass: "desc mt10" }, [
                                  _vm._v("设置秒杀活动可以参与的商户星级。"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activeName == "second"
                        ? [
                            !_vm.isEdit || _vm.pageType
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "acea-row row-between-wrapper",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "acea-row mb20" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addGoods()
                                              },
                                            },
                                          },
                                          [_vm._v("添加产品")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-dropdown",
                                          {
                                            staticClass: "ml10 mr10",
                                            attrs: {
                                              disabled: _vm.isShowCheck,
                                              size: "small",
                                              "split-button": "",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  批量设置\n                  "
                                            ),
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    attrs: {
                                                      disabled: _vm.isShowCheck,
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.setPrice(2)
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("限量")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-dropdown-item",
                                                  {
                                                    attrs: {
                                                      disabled: _vm.isShowCheck,
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.setPrice(1)
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("活动价")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              disabled: _vm.isShowCheck,
                                            },
                                            on: { click: _vm.batchDel },
                                          },
                                          [_vm._v("批量删除")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.listLoading,
                                    expression: "listLoading",
                                  },
                                ],
                                ref: "tableList",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "row-key": "id",
                                  data: _vm.proData,
                                  size: "small",
                                  "default-expand-all": "",
                                  "tree-props": { children: "children" },
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { width: "30" },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { width: "50" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function (scope) {
                                          return [
                                            _c("el-checkbox", {
                                              attrs: {
                                                indeterminate:
                                                  _vm.isIndeterminate,
                                              },
                                              on: {
                                                change:
                                                  _vm.handleCheckAllChange,
                                              },
                                              model: {
                                                value: _vm.checkAll,
                                                callback: function ($$v) {
                                                  _vm.checkAll = $$v
                                                },
                                                expression: "checkAll",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return !scope.row.sku
                                            ? [
                                                _c("el-checkbox", {
                                                  attrs: {
                                                    value: scope.row.checked,
                                                  },
                                                  on: {
                                                    change: function (v) {
                                                      return _vm.handleCheckOneChange(
                                                        v,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            : undefined
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { width: "240", label: "产品信息" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "acea-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "demo-image__preview mr10",
                                                  },
                                                  [
                                                    _c("el-image", {
                                                      staticStyle: {
                                                        width: "36px",
                                                        height: "36px",
                                                      },
                                                      attrs: {
                                                        src: scope.row.image,
                                                        "preview-src-list": [
                                                          scope.row.image,
                                                        ],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row_title line2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(scope.row.name)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1876274800
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "categoryName",
                                    label: "产品分类",
                                    "min-width": "80",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "merName",
                                    label: "商户名称",
                                    "min-width": "80",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "price",
                                    label: "售价",
                                    width: "120",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label:
                                      _vm.isEdit && !_vm.pageType
                                        ? "限量剩余"
                                        : "库存",
                                    "min-width": "80",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.isEdit && !_vm.pageType
                                                    ? scope.row.quota
                                                    : scope.row.stock
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3685599434
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "限量", width: "120" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return scope.row.sku
                                            ? [
                                                _vm.isEdit && !_vm.pageType
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.quotaShow
                                                        )
                                                      ),
                                                    ])
                                                  : _c("el-input-number", {
                                                      staticClass:
                                                        "input_width",
                                                      attrs: {
                                                        disabled:
                                                          _vm.isEdit &&
                                                          !_vm.pageType,
                                                        precision: 0,
                                                        min: 0,
                                                        max: scope.row.stock,
                                                        controls: false,
                                                      },
                                                      model: {
                                                        value: scope.row.quota,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "quota",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.quota",
                                                      },
                                                    }),
                                              ]
                                            : undefined
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "activityPrice",
                                    label: "活动价格",
                                    width: "120",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return scope.row.sku
                                            ? [
                                                _c("el-input-number", {
                                                  staticClass: "input_width",
                                                  attrs: {
                                                    disabled:
                                                      _vm.isEdit &&
                                                      !_vm.pageType,
                                                    type: "number",
                                                    precision: 2,
                                                    min: 0.01,
                                                    max: 99999,
                                                    controls: false,
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.activityPrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "activityPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.activityPrice",
                                                  },
                                                }),
                                              ]
                                            : undefined
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "sort",
                                    label: "排序",
                                    width: "120",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return !scope.row.sku
                                            ? [
                                                _c("el-input-number", {
                                                  staticClass: "input_width",
                                                  attrs: {
                                                    disabled:
                                                      _vm.isEdit &&
                                                      !_vm.pageType,
                                                    type: "number",
                                                    precision: 0,
                                                    min: 0,
                                                    max: 99999,
                                                    controls: false,
                                                  },
                                                  model: {
                                                    value: scope.row.sort,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "sort",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.sort",
                                                  },
                                                }),
                                              ]
                                            : undefined
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "操作",
                                    width: "60",
                                    fixed: "right",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            !scope.row.sku
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                      disabled:
                                                        _vm.isEdit &&
                                                        !_vm.pageType,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleDelete(
                                                          scope.$index,
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2589770081
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-card",
            { staticClass: "fixed-card box-card", attrs: { "dis-hover": "" } },
            [
              _c(
                "div",
                { staticClass: "acea-row row-center-wrapper" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeName == "first",
                          expression: "activeName == 'first'",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.activeName = "second"
                        },
                      },
                    },
                    [_vm._v("下一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeName == "second" && !_vm.pageType,
                          expression: "activeName == 'second' && !pageType",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.activeName = "first"
                        },
                      },
                    },
                    [_vm._v("上一步")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: [
                            "platform:seckill:activity:add",
                            "platform:seckill:activity:update",
                          ],
                          expression:
                            "['platform:seckill:activity:add', 'platform:seckill:activity:update']",
                        },
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.activeName == "second" ||
                            (_vm.activeName == "first" &&
                              _vm.isEdit &&
                              !_vm.pageType),
                          expression:
                            "activeName == 'second' || (activeName == 'first' && isEdit && !pageType)",
                        },
                        {
                          name: "debounceClick",
                          rawName: "v-debounceClick",
                          value: function () {
                            _vm.submitForm("ruleForm")
                          },
                          expression:
                            "\n            () => {\n              submitForm('ruleForm');\n            }\n          ",
                        },
                      ],
                      attrs: { type: "primary", size: "small" },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("activity", {
        key: "keyNum",
        ref: "activityModal",
        on: { onChange: _vm.setActivity },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }