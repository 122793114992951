var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-height": "60vh", overflow: "auto" } },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "formValidate",
          staticClass: "demo-formValidate",
          attrs: {
            model: _vm.formValidate,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _vm._l(_vm.formValidate.timeValue, function (item, index) {
            return _c(
              "el-row",
              { key: index },
              [
                _c(
                  "el-col",
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "时间：",
                          prop: "timeValue." + index + ".time",
                          rules: _vm.rules.time,
                        },
                      },
                      [
                        item.timeMark == 1
                          ? _c("el-date-picker", {
                              style: _vm.disabled
                                ? "width: 225px"
                                : "width:200px",
                              attrs: {
                                type: "daterange",
                                "range-separator": "至",
                                "start-placeholder": "开始",
                                "end-placeholder": "结束",
                                "value-format": "yyyy-MM-dd",
                                "picker-options": _vm.pickerOptions,
                                format: "yyyy-MM-dd",
                                disabled: _vm.disabled,
                              },
                              on: { change: _vm.dataChange },
                              model: {
                                value: item.time,
                                callback: function ($$v) {
                                  _vm.$set(
                                    item,
                                    "time",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "item.time",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        item.timeMark == 0
                          ? _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: "",
                                resize: "none",
                                placeholder: "请输入内容",
                                disabled: _vm.disabled,
                              },
                              model: {
                                value: item.time,
                                callback: function ($$v) {
                                  _vm.$set(item, "time", $$v)
                                },
                                expression: "item.time",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { xl: 6, lg: 6, md: 12, sm: 24, xs: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "销售价格：",
                          prop: "timeValue." + index + ".money",
                          rules: _vm.rules.money,
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            disabled: _vm.disabled,
                            type: "number",
                            placeholder: "请输入销售价格",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              return _vm.keyupEvent("money", item.money, index)
                            },
                          },
                          model: {
                            value: item.money,
                            callback: function ($$v) {
                              _vm.$set(
                                item,
                                "money",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "item.money",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { xl: 6, lg: 6, md: 12, sm: 24, xs: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "结算价格：",
                          prop: "timeValue." + index + ".settlement",
                          rules: _vm.rules.settlement,
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            disabled: _vm.disabled,
                            type: "number",
                            placeholder: "请输入结算价格",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              return _vm.keyupEvent(
                                "settlement",
                                item.settlement,
                                index
                              )
                            },
                          },
                          model: {
                            value: item.settlement,
                            callback: function ($$v) {
                              _vm.$set(
                                item,
                                "settlement",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "item.settlement",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    attrs: {
                      xl: 6,
                      lg: _vm.disabled ? 9 : 8,
                      md: 12,
                      sm: 24,
                      xs: 24,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "原价格：",
                          prop: "timeValue." + index + ".otPrice",
                          rules: _vm.rules.otPrice,
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            disabled: _vm.disabled,
                            type: "number",
                            placeholder: "请输入原价格",
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              return _vm.keyupEvent(
                                "otPrice",
                                item.otPrice,
                                index
                              )
                            },
                          },
                          model: {
                            value: item.otPrice,
                            callback: function ($$v) {
                              _vm.$set(
                                item,
                                "otPrice",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "item.otPrice",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { xl: 2, lg: 2, md: 12, sm: 24, xs: 24 } },
                  [
                    _vm.formValidate.timeValue.length > 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.disabled,
                                expression: "!disabled",
                              },
                            ],
                            staticClass: "mr15",
                            staticStyle: { "margin-left": "20px" },
                            attrs: { type: "primary", icon: "md-add" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteBtn(index)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.disabled,
                      expression: "!disabled",
                    },
                  ],
                  staticClass: "mr15",
                  attrs: { type: "primary", icon: "md-add" },
                  on: { click: _vm.addBtn },
                },
                [_vm._v("添加新时间")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.disabled
            ? _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["merchant:product:update"],
                          expression: "['merchant:product:update']",
                        },
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.disabled,
                          expression: "!disabled",
                        },
                      ],
                      attrs: {
                        size: "mini",
                        type: "primary",
                        loading: _vm.loadingbtn,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.resetForm } },
                    [_vm._v("重置")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }