var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingFrom,
              expression: "loadingFrom",
            },
          ],
          ref: "dataForm",
          attrs: {
            inline: true,
            model: _vm.dataForm,
            "label-width": "110px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "lang",
              attrs: { label: "区域销售名称", prop: "marketName" },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isDisabled,
                  placeholder: "请输入商户名称",
                },
                model: {
                  value: _vm.dataForm.marketName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dataForm,
                      "marketName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dataForm.marketName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "inline",
              attrs: { label: "负责人姓名", prop: "principal" },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isDisabled,
                  placeholder: "请输入负责人姓名",
                },
                model: {
                  value: _vm.dataForm.principal,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dataForm,
                      "principal",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dataForm.principal",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "inline",
              attrs: {
                label: "负责人手机号",
                prop: "principalPhone",
                "label-width": "200",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isDisabled,
                  placeholder: "负责人手机号",
                },
                model: {
                  value: _vm.dataForm.principalPhone,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.dataForm,
                      "principalPhone",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "dataForm.principalPhone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.pid != 0 || _vm.merId == 0
            ? _c(
                "el-form-item",
                { attrs: { label: "父级" } },
                [
                  _c("el-cascader", {
                    ref: "cascader",
                    staticStyle: { width: "100%" },
                    attrs: {
                      disabled: _vm.isDisabled,
                      options: _vm.allTreeList,
                      filterable: "",
                      props: _vm.categoryProps,
                    },
                    model: {
                      value: _vm.dataForm.pid,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "pid", $$v)
                      },
                      expression: "dataForm.pid",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c("el-switch", {
                attrs: { "active-text": "关闭", "inactive-text": "开启" },
                model: {
                  value: _vm.dataForm.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "status", $$v)
                  },
                  expression: "dataForm.status",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isDisabled
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleClose("dataForm")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: [
                        "platform:merchant:add",
                        "platform:merchant:update",
                      ],
                      expression:
                        "['platform:merchant:add', 'platform:merchant:update']",
                    },
                  ],
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.onsubmit("dataForm")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }