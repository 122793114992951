var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-height": "60vh", overflow: "auto" } },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "formValidate",
          staticClass: "demo-formValidate",
          attrs: {
            model: _vm.formValidate,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _vm._l(_vm.formValidate.timeValue, function (item, index) {
            return _c(
              "el-row",
              { key: index },
              [
                _c(
                  "el-col",
                  { attrs: { lg: 10 } },
                  [
                    _vm.type != 1
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "时间：",
                              prop: "timeValue." + index + ".time",
                              rules: _vm.rules.time,
                            },
                          },
                          [
                            item.timeMark == 1
                              ? _c("el-date-picker", {
                                  attrs: {
                                    type: "daterange",
                                    "range-separator": "至",
                                    "start-placeholder": "开始",
                                    "end-placeholder": "结束",
                                    "value-format": "yyyy-MM-dd",
                                    "picker-options": _vm.pickerOptions,
                                    format: "yyyy-MM-dd",
                                    disabled: true,
                                  },
                                  on: { change: _vm.dataChange },
                                  model: {
                                    value: item.time,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        item,
                                        "time",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "item.time",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            item.timeMark == 0
                              ? _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    autosize: "",
                                    resize: "none",
                                    placeholder: "请输入内容",
                                    disabled: true,
                                  },
                                  model: {
                                    value: item.time,
                                    callback: function ($$v) {
                                      _vm.$set(item, "time", $$v)
                                    },
                                    expression: "item.time",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-col", { attrs: { lg: 24 } }),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { xl: 6, lg: 8, md: 12, sm: 24, xs: 24 } },
                  [
                    _c("el-form-item", { attrs: { label: "产品售价：" } }, [
                      _c("div", [_vm._v(_vm._s(item.money))]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { xl: 6, lg: 8, md: 12, sm: 24, xs: 24 } },
                  [
                    _c("el-form-item", { attrs: { label: "结算价格：" } }, [
                      _c("div", [_vm._v(_vm._s(item.settlement))]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { xl: 6, lg: 8, md: 12, sm: 24, xs: 24 } },
                  [
                    _c("el-form-item", { attrs: { label: "利润：" } }, [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            (
                              (item.money * 100 - item.settlement * 100) /
                              100
                            ).toFixed(2)
                          )
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-col", { attrs: { lg: 24 } }),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { xl: 6, lg: 10, md: 12, sm: 24, xs: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "一级返佣：" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入",
                            disabled: _vm.disabled,
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              return _vm.keyupEvent(
                                "brokerage",
                                item.brokerage,
                                index
                              )
                            },
                          },
                          model: {
                            value: item.brokerage,
                            callback: function ($$v) {
                              _vm.$set(item, "brokerage", $$v)
                            },
                            expression: "item.brokerage",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { xl: 6, lg: 10, md: 12, sm: 24, xs: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "二级返佣：" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入",
                            disabled: _vm.disabled,
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              return _vm.keyupEvent(
                                "brokerageTwo",
                                item.brokerageTwo,
                                index
                              )
                            },
                          },
                          model: {
                            value: item.brokerageTwo,
                            callback: function ($$v) {
                              _vm.$set(item, "brokerageTwo", $$v)
                            },
                            expression: "item.brokerageTwo",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("el-form-item"),
          _vm._v(" "),
          !_vm.disabled
            ? _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        loading: _vm.loadingbtn,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.resetForm } },
                    [_vm._v("重置")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }