"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: '',
  props: {
    title: {
      type: [String],
      default: '库存'
    },
    Visible: Boolean,
    datasHead: {
      type: [Array],
      default: function _default() {
        return [];
      }
    },
    dataList: {
      type: [Array],
      default: function _default() {
        return [];
      }
    }
  },
  components: {},
  data: function data() {
    return {
      optionValue: '',
      List: [],
      dateValue: ''
    };
  },
  computed: {},
  mounted: function mounted() {},
  watch: {
    datasHead: {
      handler: function handler(newVlaue) {
        this.dateValue = '';

        // console.log('firstss', newVlaue);
        if (newVlaue.length) {
          this.optionValue = '';
          this.optionValue = newVlaue[0].value;
        }
      },
      deep: true
    },
    optionValue: {
      handler: function handler(newVlaue) {
        this.dateValue = '';
        this.$emit('headChange', {
          id: this.optionValue
        });
      },
      deep: true
    },
    dataList: {
      handler: function handler(newVlaue) {
        console.log('first', newVlaue);
        if (newVlaue.length) {
          //   this.optionValue = newVlaue[0].value;
          this.List = JSON.parse(JSON.stringify(newVlaue));
        }
      },
      deep: true
    }
  },
  methods: {
    keyupEventKC: function keyupEventKC(key, val, index) {
      var re = /^\D*([0-9]\d*)?.*$/;
      val = String(val);
      this.List[index][key] = this.$set(this.List[index], key, val.replace(re, '$1'));
      this.List[index][key] = Number(this.List[index][key]);
    },
    headChange: function headChange(e) {},
    close: function close() {
      this.$emit('close', false);
    },
    submitForm: function submitForm() {
      this.$emit('confirm', {
        Id: this.optionValue,
        list: this.List
      });
    },
    seachList: function seachList() {
      this.$emit('headChange', {
        id: this.optionValue,
        dateValue: this.dateValue
      });
    }
  }
};