var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _vm.checkPermi(["admin:statistics:home:index"]) && _vm.islog
        ? _c("base-info", { ref: "baseInfo" })
        : _vm._e(),
      _vm._v(" "),
      _vm.islog ? _c("grid-menu", { staticClass: "mb20" }) : _vm._e(),
      _vm._v(" "),
      _vm.islog ? _c("user-overview") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }