"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tools = require("@/utils/tools");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'creatComment',
  props: {
    manyAttrValueObj: {
      type: [Object, Array],
      default: function _default() {
        return [];
      }
    },
    disabled: {
      type: [Boolean],
      default: true
    }
  },
  data: function data() {
    return {
      ManyAttrValue: [],
      loadingbtn: false,
      loading: false,
      image: '',
      // formValidate: Object.assign({}, defaultObj),
      formValidate: {
        timeValue: [{
          time: '',
          money: '',
          price: '',
          cost: '',
          otPrice: '',
          settlement: ''
        }]
      },
      rules: {
        time: [{
          required: true,
          message: '请选择时间',
          trigger: 'change'
        }],
        money: [{
          required: true,
          message: '请输入销售价格',
          trigger: 'change'
        }],
        price: [{
          required: true,
          message: '请输入结算价格',
          trigger: 'change'
        }],
        cost: [{
          required: true,
          message: '请输入成本价格',
          trigger: 'change'
        }],
        otPrice: [{
          required: false,
          message: '请输入原价格',
          trigger: 'change'
        }],
        settlement: [{
          required: true,
          message: '请输入结算价格',
          trigger: 'change'
        }]
      },
      manyTabTit: {},
      manyTabDate: {},
      radio: '',
      pickerOptions: {
        disabledDate: this.disabledDate
      },
      dateArr: []
    };
  },
  computed: {
    timecs: function timecs() {
      return this.dateArr;
    }
  },
  created: function created() {
    if (this.manyAttrValueObj && this.manyAttrValueObj.length) {
      this.formValidate.timeValue = (0, _tools.deepClone)(this.manyAttrValueObj);
      this.dataChange();
    }
  },
  watch: {
    manyAttrValueObj: function manyAttrValueObj(newValue) {
      console.log('first manyAttrValueObj', newValue);
      if (newValue && newValue.length) {
        this.formValidate.timeValue = (0, _tools.deepClone)(newValue);
        this.dataChange();
      }
    }
  },
  methods: {
    //价格输入
    keyupEvent: function keyupEvent(key, val, index) {
      var re = /^\D*([0-9]\d*\.?\d{0,2})?.*$/;
      val = String(val);
      console.log('first', key, val, index, this.formValidate.timeValue[index][key]);
      this.formValidate.timeValue[index][key] = this.$set(this.formValidate.timeValue[index], key, val.replace(re, '$1'));
    },
    dataChange: function dataChange(e) {
      var arr = [];
      this.formValidate.timeValue.forEach(function (item) {
        if (item.time) {
          arr.push({
            start: new Date(item.time[0]).getTime(),
            end: new Date(item.time[1]).getTime()
          });
        }
      });
      this.dateArr = [].concat(arr);
    },
    //禁用范围
    disabledDate: function disabledDate(time) {
      // console.log('first', time, this.timecs);
      var curDate = new Date().getTime();
      var three = 90 * 24 * 3600 * 1000;
      var threeMonths = curDate + three;
      // console.log('first', time.getTime() < Date.now() + 8.64e6, time.getTime() > threeMonths, threeMonths);
      var flag = false;
      if (this.timecs.length) {
        var Range;
        this.timecs.forEach(function (item) {
          Range = Range || time.getTime() >= item.start && time.getTime() < item.end;
        });
        return time.getTime() < Date.now() + 8.64e6 || time.getTime() > threeMonths || Range;
      }
      return time.getTime() < Date.now() + 8.64e6 || time.getTime() > threeMonths;
    },
    deleteBtn: function deleteBtn(index) {
      this.formValidate.timeValue.splice(index, 1);
      this.dataChange();
    },
    addBtn: function addBtn() {
      this.formValidate.timeValue.push({
        time: '',
        money: '',
        price: '',
        cost: '',
        otPrice: '',
        settlement: ''
      });
    },
    changeType: function changeType(row, index) {
      row.checked = true;
      this.formValidate.attrValueId = row.id;
    },
    handleRemove: function handleRemove(i) {
      this.formValidate.pics.splice(i, 1);
    },
    resetForm: function resetForm() {
      this.dateArr = [];
      console.log(this.formValidate.timeValue, 'this.formValidate.timeValue', this.manyAttrValueObj);
      // this.formValidate.timeValue = [...this.manyAttrValueObj];
      this.formValidate.timeValue = (0, _tools.deepClone)(this.manyAttrValueObj);
    },
    submitForm: function submitForm() {
      var _this = this;
      // console.log(this.formValidate);
      // this.timecs = [];
      this.$refs['formValidate'].validate(function (valid) {
        if (valid) {
          //   if (this.currentTab++ > 2) this.currentTab = 0;
          _this.$emit('succee', _this.formValidate.timeValue);
        } else {
          _this.$message.warning('请填时间和价格！');
        }
      });
      this.dateArr = [];
    }
  }
};