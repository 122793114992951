var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-form",
                {
                  ref: "userFrom",
                  attrs: {
                    inline: "",
                    size: "small",
                    model: _vm.tableFrom,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 24, lg: 18, xl: 18 } },
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "区域名称：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      placeholder: "请输入用户昵称",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.tableFrom.marketName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tableFrom,
                                          "marketName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "tableFrom.marketName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "销售状态：" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "selWidth",
                                  attrs: { type: "button" },
                                  model: {
                                    value: _vm.tableFrom.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tableFrom, "status", $$v)
                                    },
                                    expression: "tableFrom.status",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "1" } },
                                    [_c("span", [_vm._v("打开")])]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { label: "0" } },
                                    [_c("span", [_vm._v("关闭")])]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 24, lg: 18, xl: 18 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "timeBox",
                              attrs: { label: "时间选择：" },
                            },
                            [
                              _c("el-date-picker", {
                                staticClass: "selWidth",
                                attrs: {
                                  align: "right",
                                  "unlink-panels": "",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  size: "small",
                                  type: "datetimerange",
                                  placement: "bottom-end",
                                  placeholder: "自定义时间",
                                },
                                model: {
                                  value: _vm.tableFrom.timeVal,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tableFrom, "timeVal", $$v)
                                  },
                                  expression: "tableFrom.timeVal",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticClass: "text-right userFrom userbtn",
                          attrs: { xs: 24, sm: 24, md: 24, lg: 6, xl: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "mr15",
                                  attrs: {
                                    type: "primary",
                                    icon: "ios-search",
                                    label: "default",
                                    size: "small",
                                  },
                                  on: { click: _vm.userSearchs },
                                },
                                [_vm._v("搜索")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "ResetSearch mr10",
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.reset("userFrom")
                                    },
                                  },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _c("span", { staticStyle: { margin: "20px" } }, [
                  _vm._v(
                    "上月：" + _vm._s(_vm.countsData.topMonth || 0) + "元"
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticStyle: { margin: "20px" } }, [
                  _vm._v(
                    "当月：" + _vm._s(_vm.countsData.thisMonth || 0) + "元"
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticStyle: { margin: "20px" } }, [
                  _vm._v("本年：" + _vm._s(_vm.countsData.year || 0) + "元"),
                ]),
                _vm._v(" "),
                _c("span", { staticStyle: { margin: "20px" } }, [
                  _vm._v(
                    "总数：" + _vm._s(_vm.countsData.countSum || 0) + "元"
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "row-key": "id",
                lazy: "",
                load: _vm.load,
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", "min-width": "80", prop: "id" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "区域",
                  "min-width": "180",
                  prop: "marketName",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "销售金额", "min-width": "180", prop: "money" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }