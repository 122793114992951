"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deepClone = deepClone;
exports.urlDownload = void 0;
//深度克隆，且解决了循环依赖的问题
function deepClone(obj) {
  var refs = new WeakMap();
  return doClone(obj, refs);
}

// 执行深度拷贝工作
// obj 待拷贝的对象
// refs key: 已经被处理过的原始对象引用；value：已经被处理过的原始对象所对应的新对象的引用
function doClone(obj, refs) {
  if (refs.has(obj)) {
    return refs.get(obj);
  }
  var oType = getObjectType(obj);
  if (oType == "Object") {
    var result = {};
    refs.set(obj, result);
    for (var key in obj) {
      result[key] = doClone(obj[key], refs);
    }
    return result;
  } else if (oType == "Array") {
    var result = [];
    for (var i = 0; i < obj.length; i++) {
      result[i] = doClone(obj[i], refs);
    }
    return result;
  } else {
    return obj;
  }
}
;
// 获得参数的类型
function getObjectType(o) {
  if (o === null) return "Null";
  if (o === undefined) return "Undefined";
  return Object.prototype.toString.call(o).slice(8, -1);
}
var urlDownload = exports.urlDownload = function urlDownload(url) {
  var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '下载文件';
  var link = document.createElement('a');
  link.download = fileName;
  link.style.display = 'none';
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};