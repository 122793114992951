import { render, staticRenderFns } from "./statistics.vue?vue&type=template&id=46b8e2cf&scoped=true"
import script from "./statistics.vue?vue&type=script&lang=js"
export * from "./statistics.vue?vue&type=script&lang=js"
import style0 from "./statistics.vue?vue&type=style&index=0&id=46b8e2cf&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46b8e2cf",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\bsh\\bsh-vue-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('46b8e2cf')) {
      api.createRecord('46b8e2cf', component.options)
    } else {
      api.reload('46b8e2cf', component.options)
    }
    module.hot.accept("./statistics.vue?vue&type=template&id=46b8e2cf&scoped=true", function () {
      api.rerender('46b8e2cf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/card/list/statistics.vue"
export default component.exports