"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _vuex = require("vuex");
var _activity = _interopRequireDefault(require("./activity.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'creatSeckill',
  components: {
    activity: _activity.default
  },
  data: function data() {
    return {
      activeName: 'first',
      ruleForm: {
        allQuota: 0,
        endDate: '',
        merStars: 1,
        name: '',
        oneQuota: 0,
        proCategory: '',
        startDate: '',
        timeIntervals: '',
        id: '',
        productList: [],
        timeVal2: [],
        timeVal: [],
        proCategorylist: []
      },
      rules: {
        name: [{
          required: true,
          message: '请输入活动名称',
          trigger: 'blur'
        }],
        share: [{
          required: true,
          message: '请选择优惠比例',
          trigger: 'change'
        }],
        timeVal: [{
          required: true,
          message: '请选择活动日期'
        }],
        discount: [{
          required: true,
          message: '请选择优惠方式'
        }],
        timeVal2: [{
          type: 'array',
          required: true,
          message: '请选择秒杀场次',
          trigger: 'change'
        }],
        merStars: [{
          required: true,
          message: '请选择商户星级',
          trigger: 'change'
        }]
      },
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      spikeTimeList: [],
      multipleSelection: [],
      activityType: null,
      proData: [],
      listLoading: false,
      checkAll: false,
      isIndeterminate: true,
      tempRoute: {},
      isShowCheck: false,
      keyNum: 0,
      loading: false
    };
  },
  created: function created() {
    this.tempRoute = Object.assign({}, this.$route);
  },
  mounted: function mounted() {
    if (!this.adminProductClassify.length) this.$store.dispatch('product/getAdminProductClassify');
    if (this.pageType) this.activeName = 'second';
    this.setTagsViewTitle();
    this.getSeckillIntervalList();
    this.isCkecked();
    if (this.$route.params.id && !this.pageType) {
      this.getInfo();
    }
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['adminProductClassify'])), {}, {
    //是否是编辑
    isEdit: function isEdit() {
      return this.$route.params.id ? true : false;
    },
    title: function title() {
      return this.$route.params.id ? '编辑' : '添加';
    },
    //页面是添加产品还是编辑页面，add添加产品，edit编辑
    pageType: function pageType() {
      return this.$route.params.type === 'add' ? true : false;
    }
  }),
  methods: {
    setTagsViewTitle: function setTagsViewTitle() {
      if (this.$route.params.id) {
        var title = this.pageType ? '添加产品' : this.$route.params.id ? '编辑秒杀活动' : '添加秒杀活动';
        var route = Object.assign({}, this.tempRoute, {
          title: "".concat(title, "-").concat(this.$route.params.id)
        });
        this.$store.dispatch('tagsView/updateVisitedView', route);
      }
    },
    // 判断选中没有
    isCkecked: function isCkecked() {
      var checked = this.proData.filter(function (item) {
        return item.checked;
      });
      if (checked.length) {
        this.isShowCheck = false;
      } else {
        this.isShowCheck = true;
      }
    },
    //全选
    handleCheckAllChange: function handleCheckAllChange(val) {
      var _this2 = this;
      this.isIndeterminate = !this.isIndeterminate;
      this.proData.forEach(function (item) {
        _this2.$set(item, 'checked', val);
      });
      this.isCkecked();
    },
    //单选
    handleCheckOneChange: function handleCheckOneChange(val, row) {
      var totalCount = this.proData.length;
      var someStatusCount = 0;
      this.$set(row, 'checked', val);
      this.proData.forEach(function (item) {
        if (item.checked === val) {
          someStatusCount++;
        }
      });
      this.checkAll = totalCount === someStatusCount ? val : !val;
      this.isIndeterminate = someStatusCount > 0 && someStatusCount < totalCount;
      this.isCkecked();
    },
    //详情
    getInfo: function getInfo() {
      var _this3 = this;
      this.loading = true;
      (0, _marketing.seckillActivityDetailApi)(this.$route.params.id).then(function (res) {
        var info = res;
        _this3.ruleForm = {
          allQuota: info.allQuota,
          endDate: info.endDate,
          merStars: info.merStars,
          name: info.name,
          oneQuota: info.oneQuota,
          proCategory: info.proCategory,
          startDate: info.startDate,
          timeIntervals: info.timeIntervals,
          id: info.id,
          productList: info.productList,
          timeVal2: info.timeIntervals.split(',').map(function (item) {
            return item * 1;
          }),
          timeVal: [info.startDate, info.endDate],
          proCategorylist: info.proCategory !== '0' ? info.proCategory.split(',').map(function (item) {
            return item * 1;
          }) : []
        };
        _this3.getAttrValue(info.productList);
        _this3.loading = false;
        //this.isIndeterminate = !this.isIndeterminate;
      }).catch(function (res) {
        _this3.loading = false;
      });
    },
    back: function back() {
      this.$router.push({
        path: "/marketing/seckill/seckillActivity"
      });
    },
    //行删除
    handleDelete: function handleDelete(index, row) {
      var _this4 = this;
      this.$modalSure('删除该秒杀产品吗？').then(function () {
        var i = _this4.proData.findIndex(function (item) {
          return item == row;
        });
        _this4.proData.splice(i, 1);
      });
    },
    setActivity: function setActivity(data, type) {
      var _this5 = this;
      if (type == 1) {
        if (data.type == 0) {
          this.proData.forEach(function (item) {
            item.children.forEach(function (item1) {
              if (item.checked) {
                _this5.$set(item1, 'activityPrice', data.price);
              }
            });
          });
        } else {
          this.proData.forEach(function (item) {
            item.children.forEach(function (item1) {
              if (item.checked) {
                _this5.$set(item1, 'activityPrice', item1.price * data.discount / 100);
              }
            });
          });
        }
      } else if (type == 2) {
        this.proData.forEach(function (item) {
          item.children.forEach(function (item1) {
            if (item.checked) {
              _this5.$set(item1, 'quota', data.activity_stock);
            }
          });
        });
      }
    },
    // 列表
    getSeckillIntervalList: function getSeckillIntervalList() {
      var _this6 = this;
      (0, _marketing.seckillIntervalListApi)().then(function (res) {
        _this6.spikeTimeList = res;
      });
    },
    addGoods: function addGoods() {
      var _this = this;
      this.$modalGoodList(function (row) {
        _this.listLoading = false;
        _this.getAttrValue(row);
      }, 'many', _this.proData);
    },
    // 选中产品
    getAttrValue: function getAttrValue(row) {
      var _this7 = this;
      var _this = this;
      row.map(function (item) {
        _this.$set(item, 'sort', item.sort ? item.sort : 0);
        _this.$set(item, 'id', item.id ? item.id : 0);
        _this.$set(item, 'checked', true);
        item.attrValue.map(function (i) {
          _this.$set(i, 'name', i.sku);
          _this.$set(i, 'merName', item.merName);
          _this.$set(i, 'categoryName', item.categoryName);
          _this.$set(i, 'quota', i.quota ? i.quota : 0);
          _this.$set(i, 'quotaShow', i.quotaShow ? i.quotaShow : 0);
          _this.$set(i, 'activityPrice', _this7.$route.params.id && !_this7.pageType ? i.price : 0);
          _this.$set(i, 'price', _this7.$route.params.id && !_this7.pageType ? i.otPrice : i.price);
        });
        _this.$set(item, 'children', item.attrValue);
      });
      _this.proData = row;
      _this.isCkecked();
    },
    batchDel: function batchDel() {
      var _this8 = this;
      this.$modalSure("\u6279\u91CF\u5220\u9664\u4EA7\u54C1\u5417\uFF1F").then(function () {
        _this8.proData = _this8.proData.filter(function (item) {
          return !item.checked;
        });
      });
    },
    //设置活动价
    setPrice: function setPrice(type) {
      this.keyNum = Math.random();
      this.$refs.activityModal.type = type;
      this.$refs.activityModal.showStatus = true;
    },
    // 具体日期
    onchangeTime2: function onchangeTime2(e) {
      this.ruleForm.timeIntervals = e.toString();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.ruleForm.timeVal = e;
      this.ruleForm.startDate = e ? e[0] : '';
      this.ruleForm.endDate = e ? e[1] : '';
    },
    submitForm: function submitForm(formName) {
      var _this9 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this9.proData.length > 0) {
            var total = 0;
            var price = 0;
            _this9.proData.map(function (item) {
              item.children.map(function (i) {
                total += i.quota;
                price += i.activityPrice;
              });
            });
            if (!total && total !== 0) return _this9.$message.warning('产品限量不能为空');
            if (!price) return _this9.$message.warning('产品秒杀价格不能为空');
            if (total < _this9.proData.length) return _this9.$message.warning('产品限量总和不能小于0');
          }
          _this9.ruleForm.productList = _this9.proData.map(function (item) {
            return {
              id: item.id,
              sort: item.sort,
              attrValue: item.attrValue.map(function (item1) {
                return {
                  activityPrice: item1.activityPrice,
                  attrValueId: item1.id,
                  quota: item1.quota
                };
              })
            };
          });
          _this9.ruleForm.proCategory = _this9.ruleForm.proCategorylist.length ? _this9.ruleForm.proCategorylist.toString() : '0';
          if (_this9.pageType) {
            (0, _marketing.seckillProAdd)({
              id: _this9.$route.params.id,
              productList: _this9.ruleForm.productList
            }).then(function (res) {
              _this9.$message.success('添加成功');
              _this9.$router.push({
                path: "/marketing/seckill/seckillActivity"
              });
            }).catch(function (res) {});
          } else {
            _this9.$route.params.id ? (0, _marketing.seckillAtivityUpdateApi)(_this9.ruleForm).then(function (res) {
              _this9.$message.success('编辑成功');
              _this9.$router.push({
                path: "/marketing/seckill/seckillActivity"
              });
            }).catch(function (res) {}) : (0, _marketing.seckillActivityAddApi)(_this9.ruleForm).then(function (res) {
              _this9.$message.success('添加成功');
              _this9.$router.push({
                path: "/marketing/seckill/seckillActivity"
              });
            }).catch(function (res) {});
          }
        } else {
          return false;
        }
      });
    }
  }
};