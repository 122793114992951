var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "box-card",
        },
        [
          _vm.isShow
            ? _c("z-b-parser", {
                directives: [
                  {
                    name: "hasPermi",
                    rawName: "v-hasPermi",
                    value: [
                      "platform:integral:get:config",
                      "platform:integral:update:config",
                    ],
                    expression:
                      "['platform:integral:get:config', 'platform:integral:update:config']",
                  },
                ],
                attrs: {
                  "form-name": _vm.formName,
                  "is-create": _vm.isCreate,
                  "edit-data": _vm.editData,
                },
                on: { submit: _vm.handlerSubmit },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }