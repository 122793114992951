var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "用户等级",
            visible: _vm.dialogVisible,
            width: "500px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "user",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.user,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "等级名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "10", placeholder: "请输入等级名称" },
                    model: {
                      value: _vm.user.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.user,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "user.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "等级级别", prop: "grade" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.user.grade,
                        callback: function ($$v) {
                          _vm.$set(_vm.user, "grade", $$v)
                        },
                        expression: "user.grade",
                      },
                    },
                    _vm._l(_vm.levelData, function (item) {
                      return _c("el-option", {
                        key: item.val,
                        attrs: {
                          label: item.name,
                          value: item.val,
                          disabled: item.disabled,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所需成长值", prop: "experience" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      placeholder: "请输入成长值",
                      min: 0,
                      max: 99999,
                      "step-strictly": "",
                    },
                    model: {
                      value: _vm.user.experience,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "experience", _vm._n($$v))
                      },
                      expression: "user.experience",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "文字颜色", prop: "backColor" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入文字颜色(例：#455A93)" },
                    model: {
                      value: _vm.user.backColor,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.user,
                          "backColor",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "user.backColor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "图标", prop: "icon" } }, [
                _c(
                  "div",
                  {
                    staticClass: "upLoadPicBox",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap("1", "icon")
                      },
                    },
                  },
                  [
                    _vm.user.icon
                      ? _c("div", { staticClass: "pictrue" }, [
                          _c("img", { attrs: { src: _vm.user.icon } }),
                        ])
                      : _vm.formValidate.icon
                      ? _c("div", { staticClass: "pictrue" }, [
                          _c("img", { attrs: { src: _vm.formValidate.icon } }),
                        ])
                      : _c("div", { staticClass: "upLoad" }, [
                          _c("i", {
                            staticClass: "el-icon-camera cameraIconfont",
                          }),
                        ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "desc mt10" }, [
                      _vm._v("尺寸：180*180px"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "背景图", prop: "backImage" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap("1", "backImage")
                        },
                      },
                    },
                    [
                      _vm.user.backImage
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", { attrs: { src: _vm.user.backImage } }),
                          ])
                        : _vm.formValidate.backImage
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: { src: _vm.formValidate.backImage },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "desc mt10" }, [
                    _vm._v("尺寸：660*300px"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("user")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: [
                        "platform:system:user:level:save",
                        "platform:system:user:level:update",
                      ],
                      expression:
                        "['platform:system:user:level:save', 'platform:system:user:level:update']",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formValidate")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }