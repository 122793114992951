var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-form",
                {
                  ref: "userFrom",
                  attrs: {
                    inline: "",
                    size: "small",
                    model: _vm.tableFrom,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 24, lg: 18, xl: 18 } },
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "区域名称：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "selWidth",
                                    attrs: {
                                      placeholder: "请输入区域名称",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.tableFrom.marketName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tableFrom,
                                          "marketName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "tableFrom.marketName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticClass: "text-right userFrom userbtn",
                          attrs: { xs: 24, sm: 24, md: 24, lg: 6, xl: 6 },
                        },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "mr15",
                                  attrs: {
                                    type: "primary",
                                    icon: "ios-search",
                                    label: "default",
                                    size: "small",
                                  },
                                  on: { click: _vm.userSearchs },
                                },
                                [_vm._v("搜索")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "ResetSearch mr10",
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.reset("userFrom")
                                    },
                                  },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:user:tag:save"],
                      expression: "['platform:user:tag:save']",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onAdd(null)
                    },
                  },
                },
                [_vm._v("\n        新增\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "row-key": "id",
                lazy: "",
                load: _vm.load,
                "tree-props": { children: "child", hasChildren: "hasChildren" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", "min-width": "80", prop: "id" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "区域销售名称", "min-width": "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", {
                          domProps: { textContent: _vm._s(row.marketName) },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "推广二维码",
                  "min-width": "180",
                  prop: "qrCode",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          [
                            _c("el-image", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: row.qrCode,
                                "preview-src-list": [row.qrCode],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "负责人",
                  "min-width": "180",
                  prop: "principal",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "负责人电话",
                  "min-width": "180",
                  prop: "principalPhone",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", "min-width": "180", prop: "status" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.status == 0
                                ? "关闭"
                                : row.status == 1
                                ? "正常"
                                : "-"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "120",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:user:tag:update"],
                                expression: "['platform:user:tag:update']",
                              },
                            ],
                            staticClass: "mr10",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onAdd(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:user:tag:delete"],
                                expression: "['platform:user:tag:delete']",
                              },
                            ],
                            attrs: { type: "text", size: "small", disable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(
                                  scope.row.id,
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.merId == 0 ? "新增" : "编辑",
            visible: _vm.dialogVisible,
            "before-close": _vm.closeModel,
            closeOnClickModal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c("creatRegion", {
                key: _vm.indexKey,
                ref: "creatMerchants",
                attrs: {
                  merId: _vm.merId,
                  "is-disabled": _vm.isDisabled,
                  pid: _vm.pid,
                },
                on: {
                  getList: _vm.getChange,
                  closeModel: _vm.closeModel,
                  coumfim: _vm.coumfimuse,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }